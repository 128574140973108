<template>
  <div>
    <div class="search s-space-x-2 s-mb-3">
      <input class="search__input" placeholder="輸入優惠券代號" v-model="code"/>
      <SharedButton
        size="xs"
        variant="primary"
        class="s-btn-bg-primary"
        @click="takeCoupon"
        block
      >
        領取
      </SharedButton>
    </div>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        v-else-if="!showLoading && coupons.length > 0"
        v-for="coupon in coupons"
        :key="coupon.id"
        :coupon="coupon"
        :show-code="false"
        @exchange="applyCoupon"
        :button-text="coupon.button_text"
      >
      </coupon-card>
      <coupon-empty v-else />
    </div>
  </div>
</template>

<script>
import couponApi from '@/apis/liff/v2/coupon'
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue"
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import * as consts from "@/consts";

export default {
  components: { CouponCard, CouponEmpty, Loading, SharedButton },
  data() {
    return {
      showLoading: true,
      code: null,
      coupons: [],
    }
  },
  mounted() {
    this.fetchCoupon()
  },
  methods: {
    async fetchCoupon() {
      this.showLoading = true
      // getCoupons: 已產生優惠卷代碼，並指派給消費者，讓消費者領取，來源為 redeem_code
      // getCouponsFromBundle: 優惠卷的領取規則為直接領取，優惠卷不需要先指派給消費者，消費者可以直接看到優惠卷並領取
      //                       來源為 redeem_code_bundle
      // 因為兩邊優惠卷的來源不同，所以資料會各自經過一次正規化
      await this.getCoupons()
      await this.getCouponsFromBundle()
      this.showLoading = false
    },
    async getCoupons() {
      this.coupons = await couponApi.getCoupons({
        status: 'not_applied',
        sort_by: 'belong_at',
      })
      this.coupons.forEach(coupon => {
        coupon.name = coupon?.redeem_code_bundle?.name
        coupon.start_at = coupon?.redeem_code_bundle?.start_at
        coupon.end_at = coupon?.redeem_code_bundle?.end_at
        coupon.button_text = '我要領取'
      })
    },
    async getCouponsFromBundle() {
      const data = await couponApi.getRedeemCodeBundles({
        take_type: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.DIRECT,
        validity_period: true,
      })
      const redeemCodeBundles = data.data
      redeemCodeBundles.forEach(bundle => {
        bundle.isRedeemCodeBundle = true
        if (bundle.already_taken) {
          bundle.button_text = '已領取'
        } else {
          bundle.button_text = bundle.can_take ? '我要領取' : '已無序號'
        }
        bundle.is_enabled = bundle.button_text == '我要領取'
      })
      this.coupons = this.coupons.concat(redeemCodeBundles)
    },
    async takeCoupon() {
      try {
        await couponApi.takeCoupon(this.code)
        this.showSuccess("領取成功，請至我的優惠券查看")
        this.code = null;
      } catch (error) {
        console.log(error)
        if (error.response.status == 406) {
          this.showFail(error.response.data.message)
        } else {
          this.showFail("查無此優惠代碼")
        }
      }
    },
    async applyCoupon(coupon) {
      try {
        if (coupon.isRedeemCodeBundle) {
          await couponApi.applyCouponFromRedeemCodeBundle(coupon)
        } else {
          await couponApi.applyCoupon(coupon)
        }
        this.showSuccess("領取成功")
        this.fetchCoupon()
      } catch (error) {
        this.showFail("領取失敗")
      }
    },
    showSuccess(message) {
      this.$swal.fire({
        title: message,
        type: "success",
      })
    },
    showFail(message) {
      this.$swal.fire({
        title: message,
        type: "error",
      })
    },
  }
}
</script>

<style lang="scss">
.search {
  display: flex;

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 80%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    height: 48px;
    width: 20%;
  }
}
</style>
